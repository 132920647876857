$(document).ready(function() {

    $(".contourNavigation input[type='submit'], .contourNavigation input[type='button']").on("click", function (e) {
		// Prevent the form being submitted just yet
		//e.preventDefault();   // NOTE: this is commented out because it breaks form submission on UmbracoForms 4.1.4

		var $this = $(this);
		var parentForm = $this.parents('form');
		var contourDiv = parentForm.closest('div');

		var trackingLabel = contourDiv.attr("class");
		var trackingCategory = $('body').attr("data-tracking-page");

        if (typeof (_gaq) !== 'undefined') {
            _gaq.push(
                // Queue the tracking event
                ['_trackEvent', trackingCategory, 'Submit', trackingLabel],
                // Queue the submit (will execute immediately after tracking is complete)
                function () {
                    // Submit the parent form
                    parentForm.submit();
                }
            );
        }
	});

	$(".clickable").click(function(){
    
		var $this = $(this);
   
		var trackingLabel = $this.attr("data-tracking-label");
		var trackingCategory = $this.attr("data-tracking-category");
		if (!trackingCategory || trackingCategory.length <= 0) {
			trackingCategory = $('body').attr("data-tracking-page");
		}

        if (typeof (_gaq) !== 'undefined') {
		    _gaq.push(['_trackEvent', trackingCategory, 'click', trackingLabel]);
        }
    });

    $(".clickableFAQ").click(function () {

        var $this = $(this);

        var trackingLabel = $this.attr("data-tracking-label");
        var trackingCategory = $this.attr("data-tracking-category");
        var trackingAction = $this.attr("data-tracking-action");
        if (!trackingCategory || trackingCategory.length <= 0) {
            trackingCategory = $('body').attr("data-tracking-page");
        }

        if (typeof (_gaq) !== 'undefined') {
            _gaq.push(['_trackEvent', trackingCategory, trackingAction, trackingLabel]);
        }
    });
  
	$('.clickableFacebook').click(function() {
		var $this = $(this);
   
		var trackingCategory = $('body').attr("data-tracking-page");
		var trackingLabel = $this.attr("data-tracking-label");

        if (typeof (_gaq) !== 'undefined') {
            _gaq.push(['_trackEvent', trackingCategory, 'click', trackingLabel]);
	    }
	});
  
	$('.clickableDiv input[type="submit"]').click(function() {
		var $this = $(this);
   
		var trackingCategory = $this.attr("data-tracking-category");
		if (!trackingCategory || trackingCategory.length <= 0) {
		    trackingCategory = $('body').attr("data-tracking-page");
		}
		var trackingLabel = $('.clickableDiv').attr("data-tracking-label");

        if (typeof (_gaq) !== 'undefined') {
            _gaq.push(['_trackEvent', trackingCategory, 'click', trackingLabel]);
        }
	});
  
});